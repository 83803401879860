.App {
  text-align: center;
}


  @font-face {
    font-family:'Nunito-SemiBold';
    src: url('./Nunito-SemiBold.ttf');
}

*{
  font-family: 'Nunito-SemiBold' !important;
  border-color: #af2b1e !important;
  
    
  
}


.btn-primary {
  background-color: #720300  !important; /* Primary button background color */
  border-color: #720300  !important; /* Primary button border color */
}


.mtext{
color: #720300 !important;
}

.card-title{
  color: #720300 !important;

}

::placeholder {
  color: #af2b1e !important; /* Placeholder color */
}

.custom-navbar {
  background-color: #fff; /* Custom background color */
}

.custom-navbar .nav-link {
  color: #720300; /* Custom text color for links */
}

.custom-navbar .Navbar-Brand {
  color: #720300;
}


.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}





.iframe-container {
  position: relative;
  width: 800px;
  height: 600px;
  transition: all 0.3s ease;
}

.iframe-container.maximized {
  width: 100vw;
  height: 100vh;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
}

.buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
}

button {
  padding: 10px 20px;
}



.navco{
background-color: red !important;

}



.radd{

  text-align: center;
}


.radonc{
  color: #af2b1e;
}











.full-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.full-page-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}




.full-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.full-page-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.text-overlay {
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: right;
}



@media (max-width: 768px) {
  h1 {

    font-size: 1.5rem; /* Adjust the font size for smaller screens */
  }
  h4 {
    font-size: 1rem; /* Adjust the font size for smaller screens */
  }
  .imgh{
    width: 100%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.2rem; /* Adjust the font size for very small screens */
  }
  h4 {
    font-size: 0.9rem; /* Adjust the font size for very small screens */
  }
  .imgh{
    width: 100%;
  }
}












.custom-shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Customize the values as needed */
}

.shadowed-hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.8); /* Customize the values as needed */
}